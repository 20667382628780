import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap/dist/css/bootstrap.css' //引用bootstrap的样式
import 'bootstrap/dist/js/bootstrap.min.js' //引用bootstrap的js
import 'jquery/dist/jquery.min.js' //引用jquery的js

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.mount('#app')

