import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: '/show'
        },
        {
            path: '/home',
            name:'home',
            component: () => import('../components/Home.vue')
        },
        {
            path:'/show',
            name:'show',
            component: () => import('../components/Show.vue')
        },
        {
            path:'/register',
            name:'register',
            component: () => import('../components/Register.vue')
        },
        {
            path:'/moniter',
            name:'moniter',
            component: () => import('../components/Moniter.vue')
        }
    ]
})
export default router